import { Button, Modal } from "antd"
import React, { useState } from "react"

type NoFeedBackDialogProps = {
  onClose: () => void;
  onConfirm: () => void;
  openModal: boolean
};

export const NoFeedBackDialog: React.FC<NoFeedBackDialogProps> = ({ onClose, onConfirm, openModal }) => {

  const [isModalOpen, setIsModalOpen] = useState(openModal);
  const DialogAlertText = "  You are leaving potential breakthroughs in the table. It just takes 5 seconds to guide Luna towards your ideal formulation. Please provide feedback to at least one formulation. Alternatively, you can generate more formulations so you can provide feedback. Just click on the \"Give me more\" button."

  const handleGiveMoreFormulation = () => {
    onConfirm()
  }

  const handleCancel = () => {
    onClose()
  };

  return (
    <>
      <Modal title="Wait!" open={isModalOpen} onCancel={handleCancel}
        footer={[
          <Button key="submit" type="primary" onClick={handleGiveMoreFormulation}>
            GIVE ME MORE
          </Button>,
        ]}>
        <p>
          {
            DialogAlertText
          }
        </p>
      </Modal>
    </>
  )
}